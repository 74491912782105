import React, { useMemo } from 'react';
import styles from './footer.module.scss';
import { usePathname } from 'next/navigation';
import Section from '@/components/Section';
import Logo from '@/components/Logo';
import { ROUTES, SOCIAL } from '@/lib/constants.mjs';
import Link from '@/components/Link';

import XIcon from '#/images/icons/x_logo.svg';
import DiscordIcon from '#/images/icons/discord_white_small.svg';
import LinkedInIcon from '#/images/icons/linkedin_white_small.svg';
import { useTranslation } from 'next-i18next';

const NavigationLink = ({
    device,
    navLinks,
}: {
    device: 'mobile' | 'desktop';
    navLinks: { label: string; to: string; disabledPrefetch?: boolean }[][];
}) => {
    return (
        <nav className={`${styles.menu} ${device}`}>
            {navLinks.map((group, index) => (
                <div className={styles.menuColumn} key={`group_${index}`}>
                    {group.map(({ label, to, disabledPrefetch = false }) => {
                        const props: any = {};
                        if (disabledPrefetch) {
                            props.prefetch = false;
                        }
                        return (
                            <Link
                                to={to}
                                {...props}
                                label={label}
                                key={to}
                                color="light"
                                className={styles.link}
                            />
                        );
                    })}
                </div>
            ))}

            <div className={`${styles.menuColumn} ${styles.socialColumn}`}>
                <a className={`small ${styles.social}`} href={SOCIAL.TWITTER}>
                    <XIcon className={styles.icon} />X (Twitter)
                </a>
                <a className={`small ${styles.social}`} href={SOCIAL.DISCORD}>
                    <DiscordIcon className={styles.icon} />
                    Discord
                </a>
                <a className={`small ${styles.social}`} href={SOCIAL.LINKEDIN}>
                    <LinkedInIcon
                        className={styles.icon}
                        style={{ width: '20px', height: '18px' }}
                    />
                    LinkedIn
                </a>
            </div>
        </nav>
    );
};

const Footer = () => {
    const excludeList = [
        'darkScanner',
        'scanner',
        'login',
        'registration',
        'coinalyze',
        'trizzy',
        'runner',
        'makeitallback',
        'twitter',
        'daancrypto',
        'woo',
        'omzcharts',
        'tealstreet',
        'thediscount',
        'share',
        'rektproof',
        'velodata',
        'c2m',
        'luckshury',
        'daviddtech',
        'tradermercury',
        'kyledoops',
        'cryptomanran',
        'sheldon',
        'coinank',
        'coinglass',
        'blofin',
    ];
    const { t } = useTranslation('common');
    const pathname = usePathname();
    const isFooterVisible = useMemo(() => {
        if (!pathname) return true;

        const normalizedPathname = pathname.replace(/\//g, ' ');
        const parts = normalizedPathname.split(' ');

        if (parts?.[1] === 'p' || parts?.[1] === 'j') return false;
        return !excludeList.some((item) => {
            return normalizedPathname.includes(item);
        });
    }, [pathname]);

    const navLinks = [
        [
            {
                label: t('about_us_menu_item_label' as any) as string,
                to: ROUTES.ABOUT,
            },
            {
                label: t('pricing_menu_item_label' as any) as string,
                to: ROUTES.PLANS,
            },
            {
                label: t('unlocked_menu_item_label' as any) as string,
                to: ROUTES.UNLOCKED,
            },
        ],
        [
            {
                label: t('journaling_menu_item_label' as any) as string,
                to: ROUTES.JOURNALING,
            },
            {
                label: t('analytics_menu_item_label' as any) as string,
                to: ROUTES.TRACKING_ANALYTICS,
            },
            {
                label: t('verifications_menu_item_label' as any) as string,
                to: ROUTES.VERIFICATION,
            },
        ],
        [
            {
                label: t('blog_menu_item_label' as any) as string,
                to: ROUTES.BLOG,
                disabledPrefetch: true,
            },
            {
                label: t('contact_us_menu_item_label' as any) as string,
                to: ROUTES.CONTACT_US,
            },
        ],
    ];

    const mobileNavLinks = [
        [
            {
                label: t('about_us_menu_item_label' as any) as string,
                to: ROUTES.ABOUT,
            },
            {
                label: t('unlocked_menu_item_label' as any) as string,
                to: ROUTES.UNLOCKED,
            },
            {
                label: t('journaling_menu_item_label' as any) as string,
                to: ROUTES.JOURNALING,
            },
            {
                label: t('verifications_menu_item_label' as any) as string,
                to: ROUTES.VERIFICATION,
            },
        ],
        [
            {
                label: t('pricing_menu_item_label' as any) as string,
                to: ROUTES.PLANS,
            },
            {
                label: t('blog_menu_item_label' as any) as string,
                to: ROUTES.BLOG,
                disabledPrefetch: true,
            },
            {
                label: t('contact_us_menu_item_label' as any) as string,
                to: ROUTES.CONTACT_US,
            },
        ],
    ];

    const termsLinks = [
        {
            label: t('testimonials_menu_item_label' as any),
            to: ROUTES.TESTIMONIALS,
        },
        {
            label: t('security_menu_item_label' as any),
            to: ROUTES.SECURITY,
        },
        {
            label: t('privacy_policy_menu_item_label' as any),
            to: ROUTES.PRIVACY,
        },
        {
            label: t('terms_of_service_menu_item_label' as any),
            to: ROUTES.TERMS,
        },
        {
            label: t('sitemap_menu_item_label' as any),
            to: ROUTES.SITEMAP,
        },
    ];

    if (!isFooterVisible) return null;

    return (
        <Section padding={'0'} className="fill-bg">
            <footer className={styles.footer}>
                <div className={styles.row}>
                    <div className={styles.logoContainer}>
                        <Logo />
                    </div>
                    <div className={styles.menuLinksContainer}>
                        <NavigationLink navLinks={navLinks} device={'desktop'} />
                        <NavigationLink navLinks={mobileNavLinks} device={'mobile'} />
                    </div>
                </div>
                <div className={styles.row}>
                    <p className={`small ${styles.label}`}>
                        {t('copyright_item_label' as any, { year: new Date().getFullYear() })}
                    </p>
                    <div className={styles.termsContainer}>
                        {termsLinks.map(({ label, to }) => (
                            <Link
                                to={to}
                                label={label as string}
                                key={to}
                                color="light"
                                className={`${styles.link} small`}
                            />
                        ))}
                    </div>
                </div>
            </footer>
        </Section>
    );
};

export default React.memo(Footer);
