import React, { PropsWithChildren } from 'react';
import styles from './tooltip.module.scss';

interface TooltipProps {
    data?: string | JSX.Element;
}
const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({ data, children }) => {
    if (!data) return <>{children}</>;

    return (
        <div className={styles.wrapper}>
            {children}
            <div className={styles.hoverTooltip}>{data}</div>
        </div>
    );
};

export default Tooltip;
