import { useEffect } from 'react';
import Websocket from '@/lib/Websocket';

const useWebsocket = () => {
    useEffect(() => {
        Websocket.connect();
        return () => Websocket.disconnect();
    }, []);
};
export default useWebsocket;
