import React, { useEffect } from 'react';
import Websocket from '@/lib/Websocket';

const useExchangeRates = () => {
    const [rates, setRates] = React.useState<{
        [key: string]: number;
    }>({
        btc: 0,
        eth: 0,
    });
    const timer = React.useRef<any>(null);

    useEffect(() => {
        timer.current = setInterval(() => {
            const currentRates = Websocket.getTickerValues();
            if (currentRates.btc !== rates.btc || currentRates.eth !== rates.eth) {
                setRates(currentRates);
            }
        }, 1000);

        return () => {
            clearInterval(timer.current);
        };
    }, []);
    return rates;
};

export default useExchangeRates;
