export const ROUTES = {
    HOME: '/',
    SCANNER: '/scanner/',
    ABOUT: '/about/',
    COINALYZE: '/coinalyze/',
    TWITTER: '/twitter/',
    TRIZZY: '/trizzy/',
    RUNNER: '/runner/',
    FEATURES: '/features/',
    PLANS: '/pricing/',
    LOGIN: '/login/',
    REGISTRATION: '/registration/',
    JOURNALING: '/features/automated-journaling/',
    VERIFICATION: '/features/verification/',
    TRADING_TOOLS: '/features/trading-tools/',
    TRACKING_ANALYTICS: '/features/tracking-and-analytics/',
    BINANCE: '/exchanges/binance/',
    BLOG: '/blog/',
    TERMS: '/terms-of-service/',
    PRIVACY: '/privacy-policy/',
    COOKIES: '/cookies-notice/',
    FAQ: '/faq/',
    UNLOCKED: '/unlocked/',
    TESTIMONIALS: '/testimonials/',
    AFFILIATES: '/affiliates/',
    404: '/404/',
    CONTACT_US: '/contact-us/',
    SECURITY: '/security/',
    RESET_PASSWORD: '/reset-password/',
    DAAN_CRYPTO: '/daancrypto/',
    WOO: '/woox/',
    TEALSTREET: '/tealstreet/',
    THE_DISCOUNT: '/thediscount/',
    OMZCHARTS: '/omzcharts/',
    REKTPROOF: '/rektproof/',
    SITEMAP: '/sitemap/',
    LEADERBOARD: '/leaderboard/',
};
export const availableLanguages = {
    en: {
        long: '🇬🇧 English',
        short: '🇬🇧 EN',
    },
    ru: {
        long: '🇷🇺 Русский',
        short: '🇷🇺 Рус',
    },
    uk: {
        long: '🇺🇦 Українська',
        short: '🇺🇦 Укр',
    },
    ko: {
        long: '🇰🇷 한국어',
        short: '🇰🇷 한국어',
    },
    ja: {
        long: '🇯🇵 日本語',
        short: '🇯🇵 日本語',
    },
    zh: {
        long: '🇨🇳 中文',
        short: '🇨🇳 中文',
    },
};

export const DASHBOARD_ROUTES = {
    LOGIN: 'auth/sso',
    SIGNUP: 'auth/sso/register',
    CHECK_USER: 'auth/checkUsername',
    REDIRECT: {},
    RESET_PASSWORD: 'auth/password/reset',
    GET_TOP_LIST: '/leader-board/top-100',
};

export const PARTNERS = {
    COINALYZE: 'coinalyze',
    COINALYZE3: 'coinalyze3',
    OMZCHARTS: 'omzcharts',
    TWITTER: 'twitter',
    RUNNER: 'runner',
    TRIZZY: 'trizzy',
    DAAN_CRYPTO: 'daancrypto',
    WOO: 'woox',
    TEALSTREET: 'tealstreet',
    THE_DISCOUNT: 'thediscount',
    REKTPROOF: 'rektproof',
    VELO_DATA: 'velodata',
    C2M: 'c2m',
    LUCKSHURY: 'luckshury',
    DAVIDDTECH: 'daviddtech',
    TRADER_MERCURY: 'tradermercury',
    KYLEDOOPS: 'kyledoops',
    CRYPTO_MAN_RAN: 'cryptomanran',
    SHELDON: 'sheldon',
    COINANK: 'coinank',
    COINGLASS: 'coinglass',
    THE_COINGLASS: 'the-coinglass',
    THE_COINGLASS_PROMO: 'the-coinglass-promo',
    BLOFIN: 'blofin',
};

export const CONTACT_EMAIL = 'support@coinmarketman.com';
export const WEBSOCKET_COIBASE = 'wss://ws-feed.pro.coinbase.com';
export const CAPTCHA_ACTIONS = {
    LOGIN: 'login',
    SIGNUP: 'signup',
    CONTACT_US: 'contactus',
    RESET_PASSWORD: 'resetpassword',
    RESEND_EMAIL: 'resendemail',
};

export const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD || '';

export const SOCIAL = {
    MAIL: 'support@coinmarketman.com',
    DISCORD: 'https://discord.gg/zu65pmBGfV',
    YOUTUBE: 'https://www.youtube.com/channel/UCSE7hYGKa16joxQY_9jawWA/videos',
    TWITTER: 'https://twitter.com/CoinMarketMan',
    LINKEDIN: 'https://www.linkedin.com/company/coin-market-manager/',
    FACEBOOK: 'https://www.facebook.com/coinmarketman/',
};

export const API_MANAGER_URL =
    'https://app.coinmarketman.com/dashboard/api-manager/add/request-new-exchange';

export const HOST = process.env.NEXT_PUBLIC_HOST || 'http://localhost:3000';
export const IMAGE_HOST = `s.coinmarketman.com`;

export const CAPTCHA_V2 = '6LeRXK8oAAAAAJ81lYgjp3xCZJQS2zOXArGyxCtT';
export const CAPTCHA_V3 = '6LcECmMaAAAAAKAgPh8N74C67UdJ23Iv0rAcJNkU';
