type IntlOptions = {
    maximumFractionDigits?: number;
    minimumFractionDigits: number;
    minimumSignificantDigits?: number;
    style?: string;
    currency?: string;
    trailingZeroDisplay: string;
};

export type FormatNumberType = (
    num: number,
    fraction?: number | null,
    currency?: string | null,
) => string;

const transformNumberToString = (value: number): string | null => {
    if (value === null || value === undefined || isNaN(value)) return '';

    let stringValue = Math.abs(+value).toString();

    const isScientific = stringValue.includes('e');

    if (isScientific) {
        const numberPart = stringValue.split('e')[0];
        let pow: number = Number(stringValue.split('e')[1]);
        let [intPart, decimals = null] = numberPart.split('.');

        if (!Number.isInteger(+numberPart)) {
            decimals = numberPart.split('.')[1];
        }

        if (pow < 0) {
            pow += intPart.length;
        }

        let decimalsString = '';

        if (pow > 0 && decimals !== null) {
            decimalsString = `${intPart}${decimals || ''}`;
            for (let i = 0; i < pow; i++) {
                decimalsString += '0';
            }
        } else {
            for (let i = 0; i < Math.abs(pow); i++) {
                decimalsString += '0';
            }
            decimalsString = `0.${decimalsString}${intPart}${decimals || ''}`;
        }
        return decimalsString;
    } else {
        return stringValue;
    }
};

export const formatNumberCut: FormatNumberType = (num, fraction = 2, currency = null) => {
    const options: IntlOptions = {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        trailingZeroDisplay: 'lessPrecision', // remove trailing 0
        //minimumSignificantDigits: 6,
    };
    if (currency) {
        options.style = 'currency';
        options.currency = currency.toUpperCase();
    }

    const stringValue = transformNumberToString(num);

    if (!stringValue) {
        return new Intl.NumberFormat('en-US', options).format(0);
    }

    let decimals: string | null = null;

    const parts = stringValue.split('.');
    const theIntPart = parseInt(stringValue, 10);

    if (parts?.[1]) {
        decimals = parts[1];

        if (fraction) {
            decimals = parts[1].substring(0, fraction);
        }
    }

    try {
        const formattedInt = new Intl.NumberFormat('en-US', options).format(theIntPart);
        return `${formattedInt}${decimals ? `.${decimals}` : ''}`;
    } catch (error) {
        let iosSafariOptions = {
            ...options,
            trailingZeroDisplay: 'auto',
        };

        const formattedInt = new Intl.NumberFormat('en-US', iosSafariOptions).format(theIntPart);
        return `${formattedInt}${decimals ? `.${decimals}` : ''}`;
    }
};

const getFractionLength = (num: number): number => {
    const stringValue = transformNumberToString(num);
    if (!stringValue) return 0;
    const parts = stringValue.split('.');
    return parts?.[1]?.length || 0;
};

const formatNumber: FormatNumberType = (num, fraction = 2, currency = null) => {
    const value = Math.abs(+num);
    const options: IntlOptions = {
        minimumFractionDigits: 0,
        trailingZeroDisplay: 'lessPrecision', // remove trailing 0
        //minimumSignificantDigits: 6,
    };
    if (currency) {
        options.style = 'currency';
        options.currency = currency.toUpperCase();
    }
    if (fraction !== null && !isNaN(fraction) && fraction >= 0) {
        options.maximumFractionDigits = fraction;
    }
    if (fraction === null) {
        options.maximumFractionDigits = getFractionLength(value);
    }

    try {
        return new Intl.NumberFormat('en-US', options).format(value);
    } catch (error) {
        let iosSafariOptions = {
            ...options,
            trailingZeroDisplay: 'auto',
        };
        return new Intl.NumberFormat('en-US', iosSafariOptions).format(value);
    }
};

export default formatNumber;
