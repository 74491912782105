import React from 'react';
import Image from 'next/image';
import styles from './desktopMenu.module.scss';
import btcLogo from '#/images/crypto/btc-logo.png';
import ethLogo from '#/images/crypto/eth-logo.png';
import Tooltip from '@/components/Tooltip';
import useExchangeRates from '@/hooks/useExchangeRates';
import formatNumber from '@/lib/formatNumber';

const Rates: React.FC = () => {
    const rates = useExchangeRates();
    return (
        <Tooltip data={'Prices taken from Coinbase Pro'}>
            <div className={styles.rates}>
                <div className={styles.rate}>
                    <Image src={btcLogo} alt={'BTC'} width={20} height={20} />
                    <p>{formatNumber(rates.btc, 2, 'usd')}</p>
                </div>

                <div className={styles.rate}>
                    <Image src={ethLogo} alt={'ETH'} width={20} height={20} />
                    <p>{formatNumber(rates.eth, 2, 'usd')}</p>
                </div>
            </div>
        </Tooltip>
    );
};
export default Rates;
