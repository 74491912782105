import React, { forwardRef, PropsWithChildren } from 'react';
import Header from '@/components/Header';

interface SectionProps {
    withHeader?: boolean;
    className?: string;
    innerClassName?: string;
    padding?: string;
    fullWidthSection?: boolean;
}

const Section = forwardRef<
    HTMLTableSectionElement,
    PropsWithChildren<SectionProps> & React.HTMLProps<HTMLTableSectionElement>
>(
    (
        {
            withHeader = false,
            fullWidthSection = false,
            className = '',
            innerClassName = '',
            children,
            padding,
            ...restProps
        },
        ref,
    ) => {
        return (
            <section
                {...restProps}
                ref={ref}
                style={{ padding }}
                className={`section ${className} ${fullWidthSection ? 'section__full-width' : ''}`}
            >
                {withHeader && <Header isTransparent />}
                <div className={`section--inner ${innerClassName}`}>{children}</div>
            </section>
        );
    },
);

export default Section;

Section.displayName = 'Section';
