interface Handshake {
    type: 'subscribe' | 'unsubscribe';
    product_ids: string[];
    channels: { name: string; product_ids: string[] }[];
}

interface Ticker {
    type: 'ticker'; // TODO - add other types
    sequence: number;
    product_id: string;
    price: string;
    open_24h: string;
    volume_24h: string;
    low_24h: string;
    high_24h: string;
    volume_30d: string;
    best_bid: string;
    best_bid_size: string;
    best_ask: string;
    best_ask_size: string;
    side: 'buy' | 'sell';
    time: string;
    trade_id: number;
    last_size: string;
}

interface TickerValues {
    'BTC-USD': number;
    'ETH-USD': number;

    [key: string]: number;
}

class Websocket {
    private ws: WebSocket | null;
    private readonly handshake: Handshake;
    private tickerValues: TickerValues;

    constructor() {
        this.ws = null;
        this.handshake = {
            type: 'subscribe',
            product_ids: ['BTC-USD', 'ETH-USD'],
            channels: [
                {
                    name: 'ticker',
                    product_ids: ['BTC-USD', 'ETH-USD'],
                },
            ],
        };

        this.tickerValues = {
            'BTC-USD': 0,
            'ETH-USD': 0,
        };
    }

    connect(): WebSocket {
        if (this.ws !== null) {
            return this.ws;
        }
        this.ws = new WebSocket('wss://ws-feed.pro.coinbase.com');

        this.ws.onopen = () => {
            // @ts-ignore
            this.ws.send(JSON.stringify(this.handshake));
            console.log('WS Connected');
        };
        this.ws.onclose = this.onClose;
        this.ws.onmessage = this.onMessage;
        this.ws.onerror = (error) => {
            console.log('Websocket error', error);
        };

        return this.ws;
    }

    subscribe(): void {
        if (this.ws === null) return;
        this.ws.send(JSON.stringify(this.handshake));
    }

    unsubscribe(): void {
        if (this.ws === null) return;
        this.ws.send(
            JSON.stringify({
                type: 'unsubscribe',
                product_ids: ['BTC-USD', 'ETH-USD'],
                channels: ['ticker'],
            }),
        );
    }

    disconnect(): void {
        if (this.ws === null) return;
        this.ws.close();
    }

    getTickerValues(): { btc: number; eth: number } {
        return {
            btc: this.tickerValues['BTC-USD'],
            eth: this.tickerValues['ETH-USD'],
        };
    }

    private onClose(): void {
        console.log('Websocket closed!');
        this.ws = null;
    }

    private handleTicker = (ticker: Ticker): void => {
        const price = this.tickerValues[ticker.product_id];
        if (+price !== +ticker.price) {
            this.tickerValues[ticker.product_id] = +ticker.price;
        }
    };

    private onMessage = (event: MessageEvent): void => {
        const message = JSON.parse(event.data);
        if (message.type === 'ticker') {
            this.handleTicker(message);
        }
    };
}

const instance = new Websocket();
export default instance;
