var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2QTflfK3Grs9UTGvgWNRq"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

export const cases = [
    'login',
    'register',
    'forgot-password',
    'reset-password',
    'sso',
    'member',
    'registration',
];

const ignoredErrors = [
    'apstag',
    'chrome-extension',
    "reading 'config'",
    'apstagLOADED is not defined',
    'mvpConfig',
    'ezDenty',
    '<anonymous>',
    'wallet must has at least one account',
    '4001',
    'Cannot redefine property',
    'Function.defineProperty(',
];

const env = process.env.NEXT_PUBLIC_HOST?.includes('https')
    ? process.env.NEXT_PUBLIC_ENVR
    : 'local';

if (env !== 'local') {
    // TODO
    Sentry.init({
        dsn: 'https://c2b87a3c09b064d81e0f4ec5670b5226@o4506053317296128.ingest.sentry.io/4506065273552896',
        enabled: process.env.NEXT_PUBLIC_ENVR === 'prod',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 0.5,
        environment: env,
        // Setting this option to true will print useful information to the console while you're setting up Sentry.
        debug: false,
        replaysOnErrorSampleRate: 1.0,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // You can remove this option if you're not planning to use the Sentry Session Replay feature:
        integrations: [
            new Sentry.Replay({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        ignoreErrors: [...ignoredErrors],
        beforeSend: (event, hint) => {
            const error: any = hint.originalException;
            if (error && error?.message) {
                for (const item of ignoredErrors) {
                    if (error.message.includes(item)) {
                        return null;
                    }
                }
            }
            return event;
        },
        beforeBreadcrumb: (event, hint) => {
            if (event?.category === 'console') {
                if (event?.level === 'log' || event?.level === 'info') {
                    return null;
                }

                if (event?.level === 'warning') {
                    if (event?.message?.includes('Conversion response error')) {
                        return null;
                    }
                }
            }

            if (event?.category === 'fetch') {
                if (event?.data?.url && event?.data?.url.includes('zopim')) {
                    return null;
                }
            }

            if (event?.category === 'xhr') {
                if (!event?.data?.url) return event;

                for (const item of cases) {
                    if (event?.data?.url.includes(item)) {
                        return null;
                    }
                }
            }
            return event;
        },
    });
}
